<template>
  <div>
<!--    <div class="mt-5 mb-3">-->
<!--      <h1 class="page-title white-space-normal">-->
<!--        {{data.personInfo.name}} {{data.personInfo.surname}} appartiene al Club dei Virtuosi-->
<!--      </h1>-->
<!--    </div>-->
    <b-card
      class="border-0"
    >
      <template #header>
        <card-header
          :title="personNameComp"
          extra-icon="award"
        />
      </template>
      <b-row class="mb-3">
        <b-col md="6" lg="4" class="mt-3 mt-md-0">
          <b-card
            class="h-100"
            body-class="d-flex align-items-center justify-content-center"
          >
            <template #header>
              <card-header
                :title="taxCodeLabel"
              />
            </template>
            <div>
              <h3>
                {{data.anagraficalInfo.taxCode}}
              </h3>
            </div>
          </b-card>
        </b-col>
        <b-col md="6" lg="4" class="mt-3 mt-md-0">
          <b-card
            class="h-100"
            body-class="d-flex align-items-center justify-content-center"
          >
            <template #header>
              <card-header
                title="Punti"
              />
            </template>
            <div>
              <h1 class="m-auto point-highlight point-highlight--100">
                {{data.score.totalPoint}}
              </h1>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" class="mt-3 mt-lg-0">
          <b-card
            class="h-100"
            body-class="d-flex align-items-center justify-content-center"
          >
            <template #header>
              <card-header
                title="Statistiche rapide"
              />
            </template>
            <div class="my-auto w-100 px-md-3">
              <div class="mb-3">
                <h6 class="mb-1">Segnalazioni positive</h6>
                <b-progress
                  :value="reportPosValue"
                  :max="reportTotalValue"
                  show-value
                  :variant="reportPosVariant"
                  :class="reportPosClasses"
                  :data-value="reportPosValue"
                >
                  <b-progress-bar :value="reportPosValue">
                    {{reportPosLabel}}
                  </b-progress-bar>
                </b-progress>
              </div>
              <div>
                <h6 class="mb-1">Segnalazioni negative</h6>
                <b-progress
                  :value="reportNegValue"
                  :max="reportTotalValue"
                  show-value
                  :variant="reportNegVariant"
                  :class="reportNegClasses"
                  :data-value="reportNegValue"
                >
                  <b-progress-bar :value="reportNegValue">
                    {{reportNegLabel}}
                  </b-progress-bar>
                </b-progress>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="mb-3">
        <b-card v-if="hasReportType" body-class="pb-0">
          <template #header>
            <card-header
              title="Segnalazioni ricevute"
            />
          </template>

          <b-row>
            <report-section
              v-for="report in userReportSections"
              :key="report.key"
              :report="report"
              :person-id="data.personId"
              :report-values="reportValueFilter(report.key)"
            />
          </b-row>
        </b-card>
        <card-collapsible
          title="Andamento Segnalazioni Ricevute"
          id="reportReceivedCollapse"
          start-collapsed
          added-classes="mt-3"
        >
          <template #body>
            <line-chart-card
              :current-filter="currentFilter"
              :search-data="searchDataReport"
              :dataset="datasetReport"
              chart-id="history"
              week
              doubleweek
              month
              year
              singleChart
            />
          </template>
        </card-collapsible>
      </div>
      <div>
        <b-card v-if="hasFeedbackType" body-class="pb-0">
          <template #header>
            <card-header
              title="Feedback richiesti"
            />
          </template>

          <b-row>
            <feedback-section-cdv
              v-for="feedback in userFeedbackSections"
              :key="feedback.key"
              :report="feedback"
              :person-id="data.personId"
              :report-values="feedbackValueFilter(feedback.key)"
            />
          </b-row>
        </b-card>
        <card-collapsible
          title="Andamento Feedback Ricevuti"
          id="reportCollapse"
          start-collapsed
          added-classes="mt-3"
        >
          <template #body>
            <line-chart-card
              :current-filter="currentFilter"
              :search-data="searchData"
              :dataset="dataset"
              chart-id="historyFeedback"
              week
              doubleweek
              month
              year
              singleChart
            />
          </template>
        </card-collapsible>
      </div>
    </b-card>
    <b-card
      class="border-0 mt-3"
      body-class="d-flex justify-content-center"
    >
      <div
        v-if="isReportFull"
      >
        <b-button
          size="lg"
          variant="warning"
          @click="openWizardModal"
        >
          ACQUISTA NUOVAMENTE
        </b-button>
        <b-button
          class="ms-3"
          size="lg"
          variant="success"
        >
          SCARICA IL REPORT COMPLETO
        </b-button>
      </div>
      <div
        v-else-if="isReportInProgress"
      >
        <h6 class="mb-0">
          <em>Report attualmente in creazione</em>
        </h6>
      </div>
      <div
        v-else
      >
        <b-button
          size="lg"
          @click="openWizardModal"
        >
          ACQUISTA IL REPORT COMPLETO
        </b-button>
      </div>
    </b-card>
    <cdv-wizard-modal
      :id="wizardModalId"
      :request-type="requestType"
      :request-product="itemKey"
      :profile-entity="entityData"
    />
  </div>
</template>

<script>

import { isNotEmpty } from '@/utils/validators';
import { normalizeDisplayName } from '../../../utils/formatterHelper';

const FeedbackSectionCdv = () => import('@/components/userSections/FeedbackSectionCdv.vue');
const ReportSection = () => import('@/components/userSections/ReportSection.vue');
const LineChartCard = () => import('@/components/chart/LineChartCard.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const CardCollapsible = () => import('@/components/cardCollapsible/CardCollapsible.vue');
const CdvWizardModal = () => import('../CdvWizardModal.vue');

export default {
  name: 'CdvSearchDataSearchItem',
  components: {
    CdvWizardModal,
    CardCollapsible,
    CardHeader,
    LineChartCard,
    FeedbackSectionCdv,
    ReportSection,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      headingScoring: 'STATUS & SCORING',
      headingStats: 'STATISTICHE',
      currentFilter: 'week',
      loading: false,
      loadingReport: false,
      dataset: [],
      datasetReport: [],
    };
  },
  computed: {
    taxCodeLabel() {
      // console.log('apollo data', this.data);
      return 'Codice Fiscale';
    },
    itemButtonLabel() {
      return 'Visualizza';
    },
    personNameComp() {
      const formattedName = normalizeDisplayName(this.data.personInfo.name);
      const formattedSurname = normalizeDisplayName(this.data.personInfo.surname);
      return `${formattedName} ${formattedSurname} appartiene al Club dei Virtuosi`;
    },
    // top scores
    reportTotalValue() {
      const a = this.data?.score?.reportReceived?.confirmedReport?.countTotal;
      const b = this.data?.score?.reportReceived?.confirmedReport?.countTotal;
      if (isNotEmpty(a) && b !== 0) return b;
      return 100;
    },
    reportPosValue() {
      const a = this.data.score.reportReceived.confirmedReport.countPositive;
      if (a === 0 && this.reportTotalValue > 0) return this.reportTotalValue;
      return a;
    },
    reportPosLabel() {
      const a = this.data.score.reportReceived.confirmedReport.countPositive;
      if (a === 0 && this.reportTotalValue > 0) return 0;
      return a;
    },
    reportPosVariant() {
      const a = this.data.score.reportReceived.confirmedReport.countPositive;
      if (a === 0 && this.reportTotalValue > 0) return 'gray-500';
      return 'success';
    },
    reportNegValue() {
      const a = this.data.score.reportReceived.confirmedReport.countNegative;
      if (a === 0 && this.reportTotalValue > 0) return this.reportTotalValue;
      return a;
    },
    reportNegLabel() {
      // console.log('aaaaa', this.data.score.reportReceived.confirmedReport.countNegative);
      const a = this.data.score.reportReceived.confirmedReport.countNegative;
      if (a === 0 && this.reportTotalValue > 0) return 0;
      return a;
    },
    reportNegVariant() {
      const a = this.data.score.reportReceived.confirmedReport.countNegative;
      if (a === 0 && this.reportTotalValue > 0) return 'gray-500';
      return 'danger';
    },
    reportPosClasses() {
      const a = this.data.score.reportReceived.confirmedReport.countPositive;
      const b = this.data.score.reportReceived.confirmedReport.countTotal;
      if ((a / b) * 100 < 10) {
        return 'progress--lg progress--value-xs progress-label';
      }
      return 'progress--lg';
    },
    reportNegClasses() {
      const a = this.data.score.reportReceived.confirmedReport.countNegative;
      const b = this.data.score.reportReceived.confirmedReport.countTotal;
      if ((a / b) * 100 < 10) {
        return 'progress--lg progress--value-xs progress-label';
      }
      return 'progress--lg';
    },
    // feedback data
    userFeedbackSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getFeedbackType'];
      return feedbackTypes.map(this.prepareFeedback);
    },
    hasFeedbackType() {
      return this.$store.getters['tableUtils/hasFeedbackType'];
    },
    // report data
    userReportSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getUserReportType'];
      // console.log('feedbackTypes', feedbackTypes);
      return feedbackTypes.map(this.prepareFeedback);
    },
    hasReportType() {
      return this.$store.getters['tableUtils/hasUserReportType'];
    },
    wizardModalId() {
      return 'wizardModal';
    },
    entityData() {
      return {
        name: this.data.personInfo.name,
        surname: this.data.personInfo.surname,
        taxCode: this.data.anagraficalInfo.taxCode,
      };
    },
    requestType() {
      return 'pr_person';
    },
    itemKey() {
      return 'pr_person_report_crp';
    },
    isReportFull() {
      return this.data?.reportFull?.status === 'COMPLETED';
    },
    isReportInProgress() {
      return this.data?.reportFull?.status === 'IN_PROGRESS';
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // feedback data
    fetchData() {
      this.searchData(this.currentFilter);
      this.searchDataReport(this.currentFilter);
    },
    prepareFeedback(type) {
      const score = this.$store.getters['score/getCurrentScore'] || {};
      const hasScore = this.hasScoreData;
      // console.log('prepareFeedback', type, hasScore, score);
      if (hasScore) {
        // console.log('prepareFeedback hasScore', type.key);
        const feedback = score.sectionTotals[type.key];
        if (isNotEmpty(feedback)) {
          return {
            ...type,
            hasValues: true,
            feedbackValues: { ...feedback },
          };
        }
      }
      return {
        ...type,
        hasValues: false,
      };
    },
    searchData(filter) {
      if (!this.loading) {
        this.loading = true;
        this.currentFilter = filter;
        const entityType = 'person';
        const entityId = this.data.personId;
        this.$store.dispatch('score/fetchFeedbackHistoryStatistics', { entityType, entityId, interval: filter })
          .then((response) => {
            console.log('fetch response', response);
            this.dataset = response.map((item) => ({ x: new Date(item.date), y: item.totalFeedback }));
            this.loading = false;
          }, (error) => {
            console.log('Error fetch', error);
            this.loading = false;
          });
      }
    },
    searchDataReport(filter) {
      if (!this.loadingReport) {
        this.loadingReport = true;
        this.currentFilter = filter;
        const entityType = 'person';
        const entityId = this.data.personId;
        this.$store.dispatch('score/fetchReportHistoryStatistics', { entityType, entityId, interval: filter })
          .then((response) => {
            console.log('fetch response report chart', response);
            this.datasetReport = response.map((item) => ({ x: new Date(item.date), y: item.totalReport }));
            this.loadingReport = false;
          }, (error) => {
            console.log('Error fetch', error);
            this.loadingReport = false;
          });
      }
    },
    reportValueFilter(key) {
      // console.log('this.data.score.reportReceived.sectionTotals', this.data.score.reportReceived.sectionTotals);
      const a = this.data.score.reportReceived.sectionTotals.filter((item) => item.key === key);
      // console.log('check key', a[0]);
      return a.length > 0 ? a[0] : {};
    },
    feedbackValueFilter(key) {
      // console.log('this.data.score.reportReceived.sectionTotals', this.data.score.reportReceived.sectionTotals);
      const a = this.data.score.sectionTotals.filter((item) => item.key === key);
      // console.log('check key', a[0]);
      return a.length > 0 ? a[0] : {};
    },
    openWizardModal() {
      this.$bvModal.show(this.wizardModalId);
    },
  },
};
</script>

<style scoped>

</style>
